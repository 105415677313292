import {h} from 'preact'
import {useState, useRef} from 'preact/hooks'

const Header = ({url, token, me}) => {
  const [burger, setBurger] = useState(false)

  const onBurger = () => setBurger(!burger)

  return (
    <header className="header">
      <span className={`burger ${burger ? 'active' : ''}`} onClick={onBurger}>
        <span />
        <span />
        <span />
      </span>
      {token ? (
        <>
          <nav className={burger ? 'active' : ''} onClick={onBurger}>
            <a className={(url === '/' || url.startsWith('/work')) ? 'active' : ''} href="/work">
              Work
            </a>
            <a className={url.startsWith('/leads') ? 'active' : ''} href="/leads">
              Leads
            </a>
            <a className={url.startsWith('/offers') ? 'active' : ''} href="/offers">
              Offers
            </a>
            <a className={url.startsWith('/transfers') ? 'active' : ''} href="/transfers">
              Transfers
            </a>
            <a className={url.startsWith('/orgs') ? 'active' : ''} href="/orgs">
              ORGs
            </a>
            <a className={url.startsWith('/nets') ? 'active' : ''} href="/nets">
              Networks
            </a>
            <a className={url.startsWith('/requests') ? 'active' : ''} href="/requests">
              Requests
            </a>
            <a className={url.startsWith('/invoices') ? 'active' : ''} href="/invoices">
              Invoices
            </a>
            <a className={url.startsWith('/whois') ? 'active' : ''} href="/whois">
              Whois
            </a>
            <a className={url.startsWith('/bl') ? 'active' : ''} href="/bl">
              BL
            </a>
            <a className={url.startsWith('/ta') ? 'active' : ''} href="/ta">
              TA
            </a>
            <a className={url.startsWith('/rent') ? 'active' : ''} href="/rent">
              Rent
            </a>
            <a className={url.startsWith('/memo') ? 'active' : ''} href="/memo">
              Memo
            </a>
          </nav>
          <a className={url.startsWith('/profile') ? 'active link' : 'link'} href={`/profile/${me.nick}`}>
            {me.nick}
          </a>
        </>
      ) : (
        <a className="active link" href="/">Login</a>
      )}
    </header>
  )
}

export default Header
